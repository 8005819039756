import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PlayIcon from '../assets/images/play.svg'
import { Title } from '../components/Title'
import { Text } from '../components/Text'
import { Styled } from '../styles/post.styles'
import { graphql } from 'gatsby'
import { CTA } from '../components/CTA'


import Carousel from 'react-elastic-carousel'


const Template = ({
  data,
  location
}) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark


  return (
    <>
      <SEO
        title={frontmatter.title.replace('<br/>', ' ')}
        description={frontmatter.description}
        image={frontmatter.img}
        pathname={frontmatter.path}
      />
      <Layout location={location}>
        <Styled.Container>
          <div className="intro">
            <div className="content">
              <h3 className="gold">{frontmatter.miniTitle}</h3>
              <Title text={frontmatter.title} className="black" />
              <Text text={frontmatter.description} className="black" />
            </div>
            <div className="image">
              <img src={frontmatter.imgBorder} alt={frontmatter.title} />
            </div>
          </div>

          <div
            className="post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />

          {frontmatter.video && (
            <a href={frontmatter.video} target="_blank" rel="noopener">
              <div className="video">
                <PlayIcon />
                <p>Clique para ver o vídeo</p>
              </div>
            </a>
          )}
          {frontmatter.slide.length > 0 &&
            frontmatter.slide[0] !== '' && (
              <><Carousel itemsToShow={1}
                pagination={false}

                easing="ease"
                tiltEasing="cubic-bezier(0.110, 1, 1.000, 0.210)"
                transitionMs={700}>
                {frontmatter.slide.map((slide, index) => (
                  <img src={slide} key={'slide' + index} />
                ))}
              </Carousel>
                <div className='spacingL'></div>
              </>
            )}
          <div className='container-cta'>
            <CTA text='Voltar' url={'/blog'} className=" m-auto" />
          </div>
          <div className='spacingL'></div>
        </Styled.Container>
      </Layout>
    </>
  )
}

export default Template

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        miniTitle
        description
        img
        imgBorder
        video
        slide
      }
    }
  }
`

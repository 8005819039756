import styled from 'styled-components'

const Container = styled.article`
  width: 100%;
  padding-top: 12rem;

  .spacingL{
    margin-top: ${({ theme }) => theme.spacingL};
  }
  .container-cta{
    width: 100%;
    margin: auto;
    text-align: center;
  }

  .m-auto{
    margin: auto;
  }

  .intro {
    min-height: 85vh;
    display: flex;

    .content {
      width: 50%;
      padding: ${({ theme }) =>
    `${theme.spacingXL} ${theme.spacingL} ${theme.spacingM} ${theme.spacingM}`};
      border-bottom-right-radius: 50%;
      z-index: 11;

      h3 {
        font-size: 2rem;
        line-height: 3.3rem;
        font-weight: ${({ theme }) => theme.fontSemiBold};
        text-transform: uppercase;
      }

      .title {
        max-width: 85%;
        margin-top: ${({ theme }) => theme.spacingXXS};
        font-family: ${({ theme }) => theme.fontFamily};
        color: ${({ theme }) => theme.black};    
      }

      .text {
        max-width: 85%;
        margin-top: ${({ theme }) => theme.spacingM};
        color: ${({ theme }) => theme.black};
      }
    }

    .image {
      width: 50%;
      max-height: 90vh;
      text-align: center;

      img {
        max-width: 100%;
        object-fit: contain;
        max-height: 100%;
        margin: auto;
      }
    }
  }

  .post-content {
    text-align: justify;


    .intro-subtitle{
      width: 70%;
      margin: 0 auto;
      text-align: center;
      font-size: 3rem;
      margin-top: ${({ theme }) => theme.spacingM};
      font-family: ${({ theme }) => theme.fontFamily};

    }
    .initial-text {
      width: 70%;
      margin: 0 auto;
      p{
        font-size: 2rem;
      }
    }

    .mb-0{
      margin-bottom: 0;
    }
    .mt-0{
      margin-top: 0;
    }

    

    h2 {
      font-size: 5rem;
      line-height: 4.5rem;
      font-weight: ${({ theme }) => theme.fontRegular};
      color: ${({ theme }) => theme.black};
      text-align: center;
      padding: 1.25rem;
      margin-top: 6rem;
    }

    p {
      font-size: 2.5rem;
      line-height: 3.5rem;
      color: ${({ theme }) => theme.black};
      text-align: left;
      margin-top: ${({ theme }) => theme.spacingM};
      text-align: justify;
      span {
        display: block;
        color: ${({ theme }) => theme.blue};
        font-size: 3.2rem;
        margin-bottom: 1rem;
      }
    }
    

    b{
      font-size: 2.5rem;
      line-height: 1;
      color: #053D7F;
      text-align: left;
      margin-top: ${({ theme }) => theme.spacingM};
    }

    .quote{
      color: #053D7F;
      text-align: center;
      font-weight: 600;
    }

    ul, ol {
      margin-top: ${({ theme }) => theme.spacingS};
      margin-left: ${({ theme }) => theme.spacingS};

      li {
        font-size: 2rem;
        line-height: 3.5rem;
        color: ${({ theme }) => theme.black};
        margin: ${({ theme }) => theme.spacingXXS} 0;
      }
    }

    .full-width-image {
      height: 55vh;
      max-height: 55vh;
      object-fit: cover;
      width: 100%;
    }

    .image-post {
      width: 85vw;
      height: 23vh;
      object-fit: cover;
      margin-top: 10rem;
      border-top-right-radius: 18rem;
    }

    .text-people {
      font-size: 25px;
      text-align: center;
    }

    .split-text {
      display: flex;
      align-items: center;
      padding: 0 ${({ theme }) => theme.spacingL};
      margin-top: ${({ theme }) => theme.spacingL};
      gap: ${({ theme }) => theme.spacingM};

      h2 {
        width: 40%;
        line-height: 6rem;
        text-align: left;
      }

      p {
        width: 60%;
        text-align: left;
      }
    }
  }
  .rec-carousel{
    width: 70%;
    height: 70vh !important;
  }
  .rec-arrow, .rec-arrow:hover, .rec-arrow:focus, &:hover:enabled, &focus:enabled {
    background-color: transparent !important;
    color: rgb(5, 61, 127) !important;
    box-shadow: none !important;
    font-size: 4rem !important;

    &[disabled]{
      display: block !important;
      visibility: hidden !important;
    }
  }
  .rec-carousel-item{
    .rec-item-wrapper{
      height: 100%;
      img{
        object-fit: cover;
        height: 70vh;
      }
    }
  }

  .video {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: ${({ theme }) => `${theme.spacingS} 0 ${theme.spacingL} 0`};

    svg {
      width: 3.5rem;

      path {
        fill: ${({ theme }) => theme.black};
      }
    }

    p, li {
      font-size: 2rem;
      text-transform: uppercase;
      margin-left: ${({ theme }) => theme.spacingXS};
      transform: translateY(2px);
    }
  }

  @media screen and (max-width: 1300px) {
    .intro {
      .content {
        width: 55%;
      }
      .image {
        width: 45%;
        padding-top: 25rem;
      }
    }

    .post-content {
      .initial-text {
        width: 85%;
      }

      .split-text {
        padding: 0 ${({ theme }) => theme.spacingM};
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .intro-subtitle{
      width: 90% !important;
    }
    .rec-carousel {
      width: 100%;
      height: 45vh !important;
      img{
        height: 45vh !important;
      }
      
    }
    .intro {
      flex-direction: column;
      min-height: unset;

      .content {
        order: 1 !important;
        width: 100%;
       
        padding: ${({ theme }) =>
    `${theme.spacingL} ${theme.spacingM} ${theme.spacingXL} `};
        text-align: center;
        border-bottom-left-radius: 25rem;
        border-bottom-right-radius: 25rem;

        .title,
        .text {
          margin: ${({ theme }) => `${theme.spacingM} auto 0 auto`};
        }
      }

      .image {
        width: 100%;
        padding-top: 0;
        margin: 0;
      }
    }

    .post-content {
      margin-top: ${({ theme }) => theme.spacingM};

      .full-width-image {
        height: 35vh;
        width: 100%;
      }

      .split-text {
        width: 100%;
        object-fit: cover;
        margin: auto;
        flex-direction: column;
        gap: ${({ theme }) => theme.spacingS};

        h2,
        p {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    .intro .content {
      padding: ${({ theme }) =>
    `${theme.spacingL} ${theme.spacingS} ${theme.spacingXL} `};

      .title,
      .text {
        max-width: unset;
      }
    }

    

    .post-content {
      .full-width-image, .image-post {
        border-top-right-radius: 0;
        width: 100%;
        height: 24vh;
        object-fit: cover;
      }

      .split-text {
        width: 100%;
      }
    }
  }
`

export const Styled = {
  Container,
}
